/* eslint-disable no-unused-vars */
import React from 'react';

export function PortraitModeOnly(props) {
	return (
		<>
			<div className="portraitMode">{props.children}</div>
			<div className="landscapeMode">
				<div className="center">
					<p>Flip back to portrait mode to continue using the app.</p>
				</div>
			</div>
		</>
	);
}
