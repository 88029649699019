export function buildQueryString(params = {}) {
	let queryParams = Object.entries(params)
		.map(([key, value]) => {
			let queryParam = encodeURIComponent(key);
			if (value) {
				queryParam += `=${encodeURIComponent(value)}`;
			}
			return queryParam;
		})
		.join('&');

	return queryParams && `?${queryParams}`;
}

export function getUrl(url, queryParams) {
	return `${url}${queryParams ? buildQueryString(queryParams) : ''}`;
}

export function handleErrors(error) {
	if (error.name === 'AbortError') {
		throw error;
	}
	process.env.NODE_ENV !== 'production' && console.log(error); // eslint-disable-line
	throw error;
}
