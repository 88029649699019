import { message } from 'antd';

export const getProductImagesToken = () => {
	var details = {
		client_id: process.env.REACT_APP_IMAGE_CLIENT_ID,
		client_secret: process.env.REACT_APP_IMAGE_CLIENT_SECRET,
	};

	var formBody = [];
	for (var property in details) {
		if (details[property]) {
			var encodedKey = encodeURIComponent(property);
			var encodedValue = encodeURIComponent(details[property]);
			formBody.push(encodedKey + '=' + encodedValue);
		}
	}
	formBody = formBody.join('&');
	return fetch(
		process.env.REACT_APP_IMAGE_URL +
			'/oauth/client_credential/accesstoken?grant_type=client_credentials',
		{
			method: 'POST',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
			body: formBody,
		},
	).then(res => res.json());
};

export const getProductImage = productCode => {
	const imageToken = JSON.parse(sessionStorage.getItem('imageToken'));
	return fetch(
		process.env.REACT_APP_IMAGE_URL + '/scworx/v1/products/' + productCode,
		{
			method: 'GET',
			headers: new Headers({
				'Content-Type': 'application/json',
				Authorization: `Bearer ${imageToken.access_token}`,
				ucn: 'scworx',
			}),
		},
	)
		.then(res => res.json())
		.catch(() => message.error(`Could not load image for ${productCode}!`));
};
