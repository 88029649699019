import { refreshToken } from './AuthService';
import { getUrl } from './HttpService';

export const getOrders = queryParams => {
	if (queryParams.orderStatus === 'ALL') {
		delete queryParams.orderStatus;
	}
	if (queryParams.searchParam === '') {
		delete queryParams.searchParam;
	}
	delete queryParams.email;
	const url = getUrl(
		process.env.REACT_APP_FSR_API + '/api/selfserve/orders',
		queryParams,
	);
	return fetch(url, {
		'Content-Type': 'application/json',
		headers: {
			Authorization:
				'Bearer ' + JSON.parse(sessionStorage.getItem('fsrToken')).access_token,
		},
	}).then(response => {
		var data = response.json();
		if (data.error && data.error === 'Token has been revoked.') {
			return refreshToken().then(() => {
				return getOrders(queryParams);
			});
		}
		return data;
	});
};
