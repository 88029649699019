import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import '../styles/login_screen.scss';
import { Form, Select, Spin, Image, message } from 'antd';
import { useHistory } from 'react-router-dom';
import { getToken, showLogin } from '../services/AuthService';
import { getProductImagesToken } from '../services/ProductImages';
import { BASE_TITLE } from '../utils/utility';

const { Option } = Select;

function LoginScreen() {
	function onFinish() {
		showLogin();
	}
	const history = useHistory();
	const { t } = useTranslation();
	var params = Object.fromEntries(
		new URLSearchParams(window.location.search).entries(),
	);
	useEffect(() => {
		getProductImagesToken()
			.then(token => {
				sessionStorage.setItem('imageToken', JSON.stringify(token));
			})
			.catch();

		if (params.code) {
			getToken({
				grant_type: 'authorization_code',
				client_id: process.env.REACT_APP_SSO_CLIENT_ID,
				client_secret: process.env.REACT_APP_SSO_CLIENT_SECRET,
				redirect_uri: process.env.REACT_APP_URL,
				code: params.code,
			})
				.then(res => {
					sessionStorage.setItem('fsrToken', JSON.stringify(res));
					if (localStorage.getItem('onboarded') !== null) {
						history.push('/accounts/updates');
					} else {
						history.push('/onboarding1');
					}
				})
				.catch(error => {
					message.error(
						'Error occured, Please click on login button again!',
						error,
					);
				});
		}
		// sets page title
		document.title = BASE_TITLE + ' - Login';
	}, []);

	if (params.code) {
		return (
			<Spin
				className="login-loading"
				indicator={<Image src="/icons/loading.gif" />}
			/>
		);
	}
	return (
		<div className="login__screen__container">
			<div className="image-placeholder">
				<Image src="/media/svg/self_serve_image.svg" preview={false} />
			</div>
			{/* <div className="login__text">{t("Login")}</div> */}
			<div className="login__screen__list">
				<Form
					onFinish={onFinish}
					className="form-container"
					initialValues={{
						'checkbox-group': ['A'],
					}}>
					<div className="login__screen__input__list"></div>

					<div className="frame31">
						<button
							className="login__screen__button ant-button btn"
							onClick={onFinish}>
							{t('Login')}
						</button>
					</div>
					<div className="frame31">
						<span
							className="forgot-password"
							onClick={() => {
								window.location.href = 'https://password.jnj.com';
							}}>
							{t('forgotPassword')}
						</span>
					</div>
				</Form>
			</div>
			<div className="language-selection">
				{/* <div className="info-title">{t("preferredLanguage")}</div> */}
				<Select
					defaultValue={localStorage.getItem('lang')}
					style={{ width: 70 }}
					onChange={val => {
						localStorage.setItem('lang', val);
						window.location.reload();
					}}>
					<Option value="en">EN</Option>
					<Option value="fr">FR</Option>
				</Select>
			</div>
		</div>
	);
}

export default LoginScreen;
