import { useEffect, useState } from 'react';
import { Progress } from 'antd';

function CircularProgressBar({ percentage, status, width }) {
	const [seconds, setSeconds] = useState(percentage);

	useEffect(() => {
		const interval = setInterval(() => {
			setSeconds(seconds => seconds - 1);
		}, 1000);
		return () => {
			clearInterval(interval);
		};
	}, []);

	return (
		<Progress
			type="circle"
			percent={100 - seconds * 10}
			status={status}
			width={width}
		/>
	);
}

export default CircularProgressBar;
