import { useState, useEffect } from 'react';
import '../styles/information_go.scss';
import { BASE_TITLE, returnSvg } from '../utils/utility';
import { useHistory } from 'react-router';
import { touchStart, touchMove, touchEnd } from '../utils/touchfunctionality';
import { useTranslation } from 'react-i18next';
import { AccountListService } from '../services/AccountListService';
import { getSearchParams } from './AccountList';
import { Image, message } from 'antd';

// Screen of first step for onboarding
function InformationGo() {
	const [pageIndex] = useState(0);
	const history = useHistory();

	// sets account limit per page
	const AccountLimit = 10;

	// set current page index
	const AccountPage = 1;

	// init the internationalization support
	const { t } = useTranslation();
	useEffect(() => {
		// Load account details to capture FSR info to be used through the application
		AccountListService(getSearchParams(true, '', AccountLimit, AccountPage))
			.then(data => {
				// handles if the account info returns error response
				if (data.length === 0 || data.error) {
					return;
				}

				// Sets FSR info in the localstorage to use all over the application
				localStorage.setItem('fsr_country', data.fsr_country);
				localStorage.setItem('fsr_name', data.fsr_name);
				localStorage.setItem('fsr_number', data.fsr_number);
				localStorage.setItem('fsr_email', data.email);
			})
			.catch(() => {
				// Handles error response
				message.error('Could not load FSR details. Please reload!');
			});

		// sets page title
		document.title = BASE_TITLE + ' - Onboarding - Information on the Go';
	}, []);
	return (
		<div
			className="onboarding-container"
			onTouchStart={touchStart(0)}
			onTouchMove={touchMove}
			onTouchEnd={touchEnd(history, 'onboarding2', 'onboarding1')}>
			<div className="img-container img-container1">
				<Image src="/media/onboarding1.png" preview={false} />
			</div>
			<div className="description">
				<div className="title-text">{t('informationOnGo')}</div>
				<div className="body-text">{t('welcomeString')}</div>
			</div>
			<div className="pagination-list">
				{/* list the dits */}
				{[1, 2, 3, 4, 5, 6, 7].map((item, index) =>
					returnSvg(Boolean(pageIndex === index)),
				)}
			</div>
			<div className="button-container">
				<div
					className="button"
					onClick={() => {
						history.push('/onboarding2');
					}}>
					{t('getStarted')}
				</div>
			</div>
		</div>
	);
}

export default InformationGo;
