import { getUrl, handleErrors } from './HttpService';

export function AccountListService(queryParams = null) {
	const url = process.env.REACT_APP_FSR_API + '/api/selfserve/accounts';
	console.log(queryParams);
	const factory = fetch(getUrl(url, queryParams), {
		method: 'GET',
		headers: {
			Authorization:
				'Bearer ' + JSON.parse(sessionStorage.getItem('fsrToken')).access_token,
		},
	})
		.then(res => res.json())
		.then(data => data)
		.catch(error => handleErrors(error));

	return factory;
}
