import { Row, Col, Typography, Divider, Image } from 'antd';
import '../styles/Help.scss';
import { Footer } from '../elements/Footer';
import { TopBar } from '../elements/TopBar';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
	BASE_TITLE,
	getFsrCustomerCareNumber,
	getgetCountryFlag,
} from '../utils/utility';
import { useEffect } from 'react';

export const Help = () => {
	const { t } = useTranslation();

	// sets page title
	useEffect(() => {
		document.title = BASE_TITLE + ' - Help';
	}, []);

	return (
		<div className="common-page-comtainer">
			<TopBar backVisibility={false} />
			<Row justify="space-around" align="middle" className="help">
				<Col span={23} className="col-container">
					<Row>
						<Col span={24}>
							<Typography.Title level={5} className="support-title">
								{t('support')}
							</Typography.Title>
						</Col>
					</Row>
					<Row>
						<Col span={24}>
							<Typography.Title level={5} className="support-sub-title">
								{t('infoGuide')}
							</Typography.Title>
						</Col>
					</Row>
					<Row>
						<Col span={24}>
							<Link to="/help-order-status" className="help-sub-link-1">
								{t('readStatuses')}
							</Link>
						</Col>
					</Row>
					<Row>
						<Col span={24}>
							<Divider />
						</Col>
					</Row>
					<Row>
						<Col span={24}>
							<Link to="/help-line-items" className="help-sub-link-2">
								{t('readLine')}
							</Link>
						</Col>
					</Row>
					{/* <Row>
						<Col span={24}>
							<Divider />
						</Col>
					</Row>
					<Row>
						<Col span={24}>
							<Typography.Title level={5} className="feedback-title">
								{t('leaveFeedback')}
							</Typography.Title>
						</Col>
					</Row>
					<Row>
						<Col span={24}>
							<Checkbox className="checkbox-text">
								{t('contactedFeedback')}
							</Checkbox>
						</Col>
					</Row> */}
					<Row>
						<Col span={24}>
							<Divider />
						</Col>
					</Row>
					<Row>
						<Col span={24}>
							<Typography.Title level={5} className="contact-info-title">
								{t('contactInformation')}
							</Typography.Title>
						</Col>
					</Row>
					<Row>
						<Col span={3} className="flag-container">
							<Image
								src={'/icons/' + getgetCountryFlag()}
								className="flag"
								preview={false}
							/>
						</Col>
						<Col span={21} className="flag-container">
							<a href={'tel:' + getFsrCustomerCareNumber()}>
								<Typography.Text className="call-text">
									{t('callCustomerService')}
								</Typography.Text>
							</a>
						</Col>
					</Row>
					<Row>
						<Col span={24}>
							<Divider />
						</Col>
					</Row>
				</Col>
			</Row>
			<Footer />
		</div>
	);
};
