// const slider = document.querySelector(".slider-container"),
//   slides = Array.from(document.querySelectorAll(".slide"));

let isDragging = false,
	startPos = 0,
	currentTranslate = 0,
	prevTranslate = 0,
	animationID = 0,
	currentIndex = 0;

export function touchStart(index) {
	return function (event) {
		console.log('kndknek');
		currentIndex = index;
		startPos = getPositionX(event);
		isDragging = true;

		// https://css-tricks.com/using-requestanimationframe/
		animationID = requestAnimationFrame(animation);
		// slider.classList.add("grabbing");
	};
}

export function touchEnd(
	history,
	endpoint,
	endpoint1,
	navigateWithoutEnpoints = false,
) {
	return function () {
		isDragging = false;
		cancelAnimationFrame(animationID);

		const movedBy = currentTranslate - prevTranslate;

		if (movedBy < -100 && currentIndex < 7) {
			if (navigateWithoutEnpoints) {
				history.goForward();
			} else {
				history.push(`/${endpoint}`);
			}
		}
		if (movedBy > 100) {
			if (navigateWithoutEnpoints) {
				history.goBack();
			} else {
				history.push(`/${endpoint1}`);
			}
		}

		setPositionByIndex();
	};

	//   slider.classList.remove("grabbing");
}

export function touchMove(event) {
	if (isDragging) {
		console.log('moving');
		const currentPosition = getPositionX(event);
		currentTranslate = prevTranslate + currentPosition - startPos;
	}
}

function getPositionX(event) {
	return event.type.includes('mouse') ? event.pageX : event.touches[0].clientX;
}

function animation() {
	//   setSliderPosition();
	if (isDragging) requestAnimationFrame(animation);
}

// function setSliderPosition() {
//   slider.style.transform = `translateX(${currentTranslate}px)`;
// }

function setPositionByIndex() {
	currentTranslate = currentIndex * -window.innerWidth;
	prevTranslate = currentTranslate;
	//   setSliderPosition();
}
