import { Image } from 'antd';
import { useTranslation } from 'react-i18next';

// returns single item for account list
// need to pass item which is a JSON object as an argument
// item should have cac_account_name, cac_account_number, updated_orders_count
// those properties are required
const AccountListItem = ({ item }) => {
	// Initiate internationalization for the screen
	const { t } = useTranslation();
	return (
		<div className="account__list__item">
			<div className="account__list__description">
				<div className="account__list__description__name">
					{item.cac_account_name}
				</div>
				<div className="account__list__main__description">
					<div className="account__list__main__description__text">
						{t('account')} #{item.cac_account_number}
					</div>
					{item.updated_orders_count > 0 ? (
						<div className="account__list__main__description__text blue-text">
							[{item.updated_orders_count} {t('orders')}] {t('updated')}
						</div>
					) : (
						<div className="account__list__main__description__text">
							{t('noUpdates')}
						</div>
					)}
				</div>
			</div>
			<div className="rightArrow-container">
				<Image preview={false} src="/media/svg/list_item.svg" />
			</div>
		</div>
	);
};

export default AccountListItem;
