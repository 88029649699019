import { Col, Row, Image, Button } from 'antd';
import { useHistory } from 'react-router-dom';
import '../styles/TopBar.scss';
import { getFsrCustomerCareNumber } from '../utils/utility';
import { LeftOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

export const TopBar = ({
	backVisibility = true,
	logoVisibility = true,
	callVisibility = true,
}) => {
	const { push } = useHistory();
	const { pathname } = useLocation();
	useEffect(() => {
		if (
			pathname === '/orders' ||
			pathname === '/accounts' ||
			pathname === 'profile' ||
			pathname === '/help' ||
			pathname === '/dashbaord'
		) {
			localStorage.removeItem('backUrl');
		}
	}, [pathname]);

	const onAppBack = () => {
		push(localStorage.getItem('backUrl'));
	};
	return (
		<Row className="top-bar">
			<Col span={3}>
				{backVisibility && (
					<Button
						className="back-button"
						icon={<LeftOutlined />}
						onClick={onAppBack}>
						Back
					</Button>
				)}
			</Col>
			<Col span={11} offset={5}>
				{logoVisibility && (
					<Image
						className="placeholder"
						preview={false}
						src="/icons/header-logo.png"
						onClick={onAppBack}
					/>
				)}
			</Col>
			<Col span={3} offset={2}>
				{callVisibility && (
					<a href={'tel:' + getFsrCustomerCareNumber()}>
						<Image
							preview={false}
							className="placeholder callicon"
							src="/icons/topbar-telephone.png"
						/>
					</a>
				)}
			</Col>
		</Row>
	);
};
