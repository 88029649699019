/* eslint-disable no-mixed-spaces-and-tabs */
const localCurrency = {
	EUR: 'fr',
	CHF: 'sv-sv',
	GBP: 'en-gb',
};
export function getLocaleCurrencyFormat(currency, number) {
	return currency
		? new Intl.NumberFormat(localCurrency[`${currency}`], {
				style: 'currency',
				currency: currency,
		  }).format(number)
		: number;
}
