import { useEffect } from 'react';
import { TopBar } from '../elements/TopBar';
import { Footer } from '../elements/Footer';
import '../styles/dashboard.scss';
import { Typography, Image } from 'antd';
import { useTranslation } from 'react-i18next';
import { BASE_TITLE } from '../utils/utility';

function Dashboard() {
	function showNotification() {
		Notification.requestPermission(function (result) {
			if (result === 'granted') {
				navigator.serviceWorker.ready.then(function (registration) {
					console.log('kjbfbefjwebfk-===');
					registration.showNotification('Vibration Sample', {
						body: 'Buzz! Buzz!',
						// icon: '../images/touch/chrome-touch-icon-192x192.png',
						vibrate: [200, 100, 200, 100, 200, 100, 200],
						tag: 'vibration-sample',
					});
				});
			}
		});
	}
	useEffect(() => {
		if (!('Notification' in window)) {
			console.log('This browser does not support notifications!');
			return;
		} else {
			Notification.requestPermission(status => {
				console.log('Notification permission status:', status);
			});
			showNotification();
		}
		// sets page title
		document.title = BASE_TITLE + ' - Dashboard';
	}, []);
	const { t } = useTranslation();

	return (
		<div className="common-page-container">
			<div className="topbar">
				<TopBar backVisibility={false} />
			</div>

			<div className="dashboard-container text-overflow-auto">
				{/* <div className="banner-notification">
          <Typography.Text className="text">
            Something went wrong.Please refresh the page to load data
          </Typography.Text>
        </div> */}
				<div style={{ padding: '0 10px' }}>
					<Image
						src="/icons/IMAGE.error-3.png"
						width=""
						height="344px"
						preview={false}
					/>
				</div>
				<div className="description">
					<Typography.Title className="title" level={4}>
						{t('dashboardComingSoon')}
					</Typography.Title>
					<Typography.Text className="text">
						{t('feedbackStringInfo')}
						<br />
						<br />
						{t('feedbackString')}
					</Typography.Text>
				</div>
			</div>
			<Footer />
		</div>
	);
}

export default Dashboard;
