import { Row, Col, Typography, Image, Button } from 'antd';
import './../styles/HelpLineItems.scss';
import { TopBar } from '../elements/TopBar';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { BASE_TITLE } from '../utils/utility';

export const HelpLineItems = () => {
	const { push } = useHistory();
	const { t } = useTranslation();
	const [lang] = useState(localStorage.getItem('lang'));

	// sets page title
	useEffect(() => {
		localStorage.setItem('backUrl', '/help');
		document.title = BASE_TITLE + ' - Help - Line Items';
	}, []);

	const isOlderIphone = () => {
		return navigator.appVersion && navigator.appVersion.indexOf('iPhone OS 13_7') > -1;
	};

	return (
		<div className="common-page-comtainer">
			<TopBar backUrl={'/help'} />
			<Row justify="space-around" align="middle" className="help-line-items" style={isOlderIphone() ? {  maxHeight: '80vh' }: {}}>
				<Col span={23} className="col-container" style={isOlderIphone() ? {maxHeight: '80vh', overflowY: 'auto', display: 'block'}: {}}>
					<Row>
						<Col span={24}>
							{lang === 'en' && (
								<Image
									className="help-image"
									src="/media/lineItemList.png"
									preview={false}
								/>
							)}
							{lang === 'fr' && (
								<Image preview={false} src="/media/svg/line_item_status.svg" />
							)}
						</Col>
					</Row>
					<Row>
						<Col span={24}>
							<Typography.Title level={5} className="order-status-title">
								{t('ordeLineItemStatus')}
							</Typography.Title>
						</Col>
					</Row>
					<Row>
						<Col span={24}>
							<Typography.Paragraph
								level={5}
								className="order-status-description-1">
								{t('orderBrokenLineItems')}
							</Typography.Paragraph>
						</Col>
					</Row>
					<Row>
						<Col span={24}>
							<Button
								className="common-active-button action-button"
								onClick={() => {
									push('/help');
								}}>
								{t('backToSupport')}
							</Button>
						</Col>
					</Row>
				</Col>
			</Row>
		</div>
	);
};
