import { Button } from 'antd';
import { useHistory } from 'react-router-dom';
import '../styles/TopBar.scss';
import { LeftOutlined } from '@ant-design/icons';

export const OBTopBar = () => {
	const { push } = useHistory();

	const onAppBack = () => {
		const currentIndex = window.location.pathname.replace('/onboarding', '');
		push('/onboarding' + (currentIndex - 1));
	};
	return (
		<div className="top-bar">
			<Button
				className="back-button"
				icon={<LeftOutlined />}
				onClick={onAppBack}>
				Back
			</Button>
		</div>
	);
};
